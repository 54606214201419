<template>
    <h1>{{ institutionData.name }}</h1>
    <h5 v-if="institutionData.cdn !== null && inNebraska">
        <b-tooltip target="_self" title="A CDN is a County District Number for a Nebraska Educational Organization.">CDN</b-tooltip>:
        <span style="font-style: italic; border-bottom: 1px solid black;">{{ institution.cdn }}</span>
    </h5>
    <div class="col-8">
        <div class="row">
            <div class="col">
                <label>{{ institutionType }} Address</label>
                <input class="form-control" v-model="institution.primaryAddress" type="text" disabled/>
            </div>
            <div class="col">
                <label>Secondary Address</label>
                <input class="form-control" v-model="secondaryAddress" disabled/>


            </div>
            <!--        <div class="col"></div>-->
        </div>
        <div class="row">
            <div class="col">
                <label>City</label>
                <input class="form-control" v-model="institution.city" disabled>
            </div>
            <div class="col">
                <label>Postal Code (Zip Code)</label>
                <input class="form-control" v-model="institution.zipCode" disabled/>
            </div>
            <!--        <div class="col"></div>-->
        </div>
        <div class="row">
            <div class="col">
                <label>Phone Number</label>
                <input class="form-control" v-model="institution.phoneNumber" disabled/>

            </div>
            <div class="col">
                <label>Fax Number</label>
                <input class="form-control" v-model="faxNumber" disabled/>
            </div>
            <!--        <div class="col"></div>-->
        </div>
        <div class="row">
            <div class="col">
                <div v-if="institutionType ==='Nebraska District' || institutionType === 'Nebraska School'">
                    <label>ESU</label>
                    <input class="form-control" v-model="institution.esu" disabled/>
                </div>
                <div v-else-if="!inNebraska">
                    <label>Country</label>
                    <input class="form-control" v-model="institution.country" disabled/>
                </div>
            </div>
            <div class="col">
                <div v-if="institutionType === 'Nebraska School'">
                    <label>District</label>
                    <input class="form-control" v-model="institution.district" disabled/>
                </div>
                <div v-else-if="!inNebraska">
                    <label>State</label>
                    <input class="form-control" v-model="institution.state" disabled/>
                </div>
            </div>
            <!--        <div class="col"></div>-->
        </div>
    </div>
    <div class="col">
        <div class="row">
            <div class="col">
                <label>Contact Info</label>
                <div v-if="contactUsers !== undefined && contactUsers.length > 0"
                     style="overflow-y: scroll; max-height: 400px; padding-left: 1.5%; padding-right: 1.5%">

                    <div v-for="user in contactUsers" :key="user.idUser">
                        <div class="card"
                             style="padding: 20px; border-radius: 5px; border: 1px solid black; margin-bottom: 2%;">
                            <div class="row">
                                <div class="col-sm">
                                    <h6 style="color: black; opacity: 1;">Contact Name</h6>
                                    <p>{{ user.firstName }} {{ user.lastName }}</p>
                                </div>
                                <div class="col-sm">
                                    <h6 style="color: black; opacity: 1;">Email Address</h6>
                                    <p>{{ user.email }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="card"
                         style="padding: 20px; border-radius: 5px; border: 1px solid black; margin-bottom: 2%;">
                        <div class="row">
                            <div class="col-sm">
                                <p style="opacity: 1; color: black;">No registered primary point of contact.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  <!--    <div class="row">-->
  <!--        <div class="col">-->
  <!--            <div class="row" v-if="institutionData.cdn !== null && inNebraska">-->
  <!--                <label>CDN</label>-->
  <!--                <input class="form-control" v-model="institution.cdn" disabled/>-->
  <!--            </div>-->
  <!--            <div class="row">-->
  <!--                <label>{{ institutionType }} Address</label>-->
  <!--                <input class="form-control" v-model="institution.primaryAddress" type="text" disabled/>-->
  <!--            </div>-->
  <!--            <div class="row">-->
  <!--                <label>City</label>-->
  <!--                <input class="form-control" v-model="institution.city" disabled>-->
  <!--            </div>-->
  <!--            <div class="row">-->
  <!--                <label>Postal Code (Zip Code)</label>-->
  <!--                <input class="form-control" v-model="institution.zipCode" disabled/>-->
  <!--            </div>-->
  <!--            <div class="row">-->
  <!--                <label>Phone Number</label>-->
  <!--                <input class="form-control" v-model="institution.phoneNumber" disabled/>-->
  <!--            </div>-->
  <!--            <div class="row" v-if="institutionData.faxNumber !== null && institutionData.faxNumber !== undefined && institutionData.faxNumber !== ''-->
  <!--            && institution.faxNumber !== 'null'">-->

  <!--                <label>Fax Number</label>-->
  <!--                <input class="form-control" v-model="institution.faxNumber" disabled/>-->
  <!--            </div>-->
  <!--            <div class="row" v-if="institutionType ==='Nebraska District' || institutionType === 'Nebraska School'">-->
  <!--                <label>ESU</label>-->
  <!--                <input class="form-control" v-model="institution.esu" disabled/>-->
  <!--            </div>-->
  <!--            <div class="row" v-if="institutionType === 'Nebraska School'">-->
  <!--                <label>District</label>-->
  <!--                <input class="form-control" v-model="institution.district" disabled/>-->
  <!--            </div>-->
  <!--            <div v-if="!inNebraska" class="row">-->
  <!--                <label>Country</label>-->
  <!--                <input class="form-control" v-model="institution.country" disabled/>-->
  <!--            </div>-->
  <!--            <div v-if="institutionData.state !== null && institutionData.country === 'United States'" class="row">-->
  <!--                <label>State</label>-->
  <!--                <input class="form-control" v-model="institution.state" disabled/>-->
  <!--            </div>-->
  <!--        </div>-->
  <!--        <div class="col"></div>-->
  <!--        <div class="col">-->
  <!--            <div class="row">-->
  <!--                <label>Contact Info</label>-->
  <!--                <div v-if="contactUsers !== undefined && contactUsers.length > 0">-->

  <!--                    <div v-for="user in contactUsers" :key="user.idUser">-->
  <!--                        <div class="card"-->
  <!--                             style="padding: 20px; border-radius: 5px; border: 1px solid black; margin: 10px;">-->
  <!--                            <div class="row">-->
  <!--                                <div class="col-sm">-->
  <!--                                    <h6 style="color: black; opacity: 1;">Contact Name</h6>-->
  <!--                                    <p>{{ user.firstName }} {{ user.lastName }}</p>-->
  <!--                                </div>-->
  <!--                                <div class="col-sm">-->
  <!--                                    <h6 style="color: black; opacity: 1;">Email Address</h6>-->
  <!--                                    <p>{{ user.email }}</p>-->
  <!--                                </div>-->
  <!--                            </div>-->
  <!--                        </div>-->
  <!--                    </div>-->
  <!--                </div>-->
  <!--                <div v-else>-->
  <!--                    <div class="card" style="padding: 20px; border-radius: 5px; border: 1px solid black; margin: 10px;">-->
  <!--                        <div class="row">-->
  <!--                            <div class="col-sm">-->
  <!--                                <p style="opacity: 1; color: black;">No registered primary point of contact.</p>-->
  <!--                            </div>-->
  <!--                        </div>-->
  <!--                    </div>-->
  <!--                </div>-->
  <!--            </div>-->
  <!--        </div>-->
  <!--    </div>-->


</template>

<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import {isEmpty} from "@/objectEmpty";

export default {
	name: "InstitutionViewTemplate",
	props: {
		institutionData: Object,
		institutionType: String,
		inNebraska: Boolean,
		contactUsers: Array
	},
	setup(props, context) {
		let institution = ref({});
		let isLoading = ref(true);

		onBeforeMount(() => {
		})

		watch(() => props.institutionData, (first, second) => {
			institution.value = props.institutionData;
			isLoading.value = false;
		})

		const faxNumber = computed(() => {
			return institution.value.faxNumber !== '' ? institution.value.faxNumber : `No Fax Number For This ${props.institutionType}`;
		})

		const secondaryAddress = computed(() => {
			return institution.value.secondaryAddress ? institution.value.secondaryAddress : `No Secondary Address For This ${props.institutionType}`;
		})

		return {
			institution,
			isLoading,
			faxNumber,
			secondaryAddress,
		}
	}
}
</script>

<style scoped>
h1 {
    opacity: 1;
    color: black;
}


</style>
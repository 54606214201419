<template>
  <div class="col-sm">
    <label>School</label>
    <select class="form-select" v-model="selectedSchool" @change="changeSchool($event)" :disabled="selectDisabled">
      <option :value="-1">Select a School</option>
      <option v-for="school in schools" :key="school.idSchool" :value="school.idSchool">{{ school.schoolName }}
      </option>
    </select>
  </div>
</template>

<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import {API_URL} from "../../../../Constants";
import axios from 'axios';

export default {
  name: "ParticipatingSchools",
  props: {
    idEsu: Number,
    idDistrict: Number,
    idSchool: Number,
    selectDisabled: Boolean
  },
  setup(props, context) {
    const schools = ref([]);
    const selectedSchool = ref(-1);

    onBeforeMount(() => {
      if (props.idDistrict <= 0) {
        getSchools('esu');
      } else {
        getSchools('district');
      }

      if (props.idSchool) selectedSchool.value = props.idSchool;
    })

    const schoolCount = computed(() => {
      return schools.value.length;
    })

    const filterByEsu = computed(() => {
      return props.idDistrict === -1;
    })

    watch(() => props.idEsu, (first, second) => {
      getSchools('esu');
    })

    watch(() => props.idDistrict, (first, second) => {
      if (parseInt(props.idDistrict) <= 0) {
        props.idEsu < 0 ? getAllSchools() : getSchools('esu');
      } else {
        getSchools('district');
      }
    })

    watch(() => props.idSchool, () => {
      selectedSchool.value = props.idSchool;
    })

    async function getSchools(orgType) {
      let resolution_uri = orgType === 'district' ? "/school/participating/byDistrict?_idDistrict=" + props.idDistrict
          : "/school/participating/byEsu?_idEsu=" + props.idEsu;
      let get_uri = API_URL + resolution_uri;
      await axios.get(get_uri)
          .then((result) => {
            schools.value = result.data;
            selectedSchool.value = props.idSchool ? props.idSchool : -1;
          }).finally(() => {
            countEmit();
          })
    }

    async function getAllSchools() {
      let get_uri = API_URL + "/school/participating";
      await axios.get(get_uri)
          .then((result) => {
            schools.value = result.data;
          }).finally(() => {
            countEmit();
          })
    }

    function changeSchool(event) {
      context.emit('selectedSchool', event.target.value);
    }

    function countEmit() {
      context.emit('schoolCount', schoolCount.value)
    }

    return {
      schools,
      schoolCount,
      selectedSchool,
      changeSchool
    }
  }
}
</script>

<style scoped>

</style>
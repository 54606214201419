<template>
  <div class="row">

    <institution-view-template :institution-data="district" :institution-type="'Nebraska District'" :in-nebraska="true"
                               :contact-users="contactUsers"/>
  </div>
  <div v-if="authUser.position.roleHierarchy === 1 || authUser.position.roleHierarchy === 6">
    <router-link :to="{name: 'EditDistrict', params: {idDistrict: idDistrict}}">
      <button class="btn-primary button" id="btn-primary">Edit This District</button>
    </router-link>
  </div>
  <div v-else>
    <hr/>
    <!--    <router-link :to="{name: 'EditDistrict', params: {idDistrict: idDistrict}}">-->
    <h6><span style="font-weight: bold;">Contact the Educational Service Unit Coordinating Council to Edit/Update Contact Information</span>
    </h6>
  </div>
<!--  <hr/>-->
<!--  <div>-->
<!--    <div class="row">-->
<!--        <h1>{{ district.name }} Hierarchy</h1>-->
<!--      <div class="col-sm-8">-->
<!--        -->
<!--      </div>-->
<!--      <div class="col-sm-4">-->
<!--        <ParticipatingSchools-->
<!--            :id-district="idDistrict"-->
<!--            @selectedSchool="updateSchool($event)"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
<!--    <div>-->
<!--      <hr/>-->
<!--      <OrganizationHierarchy-->
<!--          :country-code="'US'"-->
<!--          :state-code="'NE'"-->
<!--          :id-esu="district.idEsu"-->
<!--          :id-district="idDistrict"-->
<!--          :school-id-array="schoolIds"-->
<!--          :id-school="selectedSchool"-->
<!--      />-->
<!--    </div>-->
<!--  </div>-->

</template>

<script>
import {useStore} from "vuex";
import {computed, onBeforeMount, ref} from "vue";
import {API_URL} from "../../../../../Constants";
import InstitutionViewTemplate from "@/views/SuperUserViews/EducationalOrganizationManagement/InstitutionViewTemplate";
import axios from "axios";
import OrganizationHierarchy from "@/components/UserOrganizationHierarchy/OrganizationHierarchy";
import ParticipatingSchools from "@/components/Reporting/OrganizationDropdowns/ParticipatingSchools";

export default {
  name: "DistrictView",
    // eslint-disable-next-line vue/no-unused-components
  components: {ParticipatingSchools, OrganizationHierarchy, InstitutionViewTemplate},
  props: {
    idDistrict: Number,
  },
  setup(props, context) {
    const store = useStore();
    const authUser = computed(() => store.getters['auth/user']);
    const district = ref({});
    const contactUsers = ref([]);
    const schoolIds = ref([]);
    const selectedSchool = ref(-1);

    onBeforeMount(() => {
      getDistrict();
      getContactUsers();
      getSchoolIds();
    })

    async function getDistrict() {
      let get_uri = API_URL + "/district/displayById?_idDistrict=" + props.idDistrict;
      await axios.get(get_uri, {
        params: {
          showUserCount: false
        }
      }).then((result) => {
            district.value = prepareDistrictData(result.data);
          })
    }

    async function getContactUsers() {
      let get_uri = API_URL + "/user/byOrgAndRole?_idOrganization=" + props.idDistrict + "&_roleHierarchy=3";
      await axios.get(get_uri)
          .then((result) => {
            contactUsers.value = result.data;
          })
    }

    async function getSchoolIds() {
      while (schoolIds.value.length > 0) {
        schoolIds.value.pop();
      }
      let get_uri = API_URL + "/school/participating/byDistrict?_idDistrict=" + props.idDistrict;
      let tmp_ids = [];
      await axios.get(get_uri)
          .then((result) => {
            for (let i = 0; i < result.data.length; i++) {
              tmp_ids[i] = result.data[i].idSchool;
            }
          }).finally(() => {
            schoolIds.value = tmp_ids;
          })
    }

    function prepareDistrictData(district) {
      return {
        name: district['districtName'],
        primaryAddress: district['primaryDistrictAddress'],
        phoneNumber: district['districtPhoneNumber'],
        city: district['districtCity'],
        cdn: district['districtCdn'],
        idDistrict: district['idDistrict'],
        esu: district['districtEsu'],
        zipCode: district['districtZipCode'],
        faxNumber: district['districtFaxNumber'],
        secondaryAddress: district['secondaryDistrictAddress'],
        idEsu: district['idEsu']
      };
    }

    function updateSchool(event) {
      selectedSchool.value = event;
    }

    return {
      district,
      authUser,
      contactUsers,
      schoolIds,
      selectedSchool,
      updateSchool
    }
  }
}
</script>

<style scoped>

</style>